import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  Signal,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormField, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SvgIconComponent } from '../../../../../../../shared/components/svg-icon/svg-icon.component';
import { ToastrService } from 'ngx-toastr';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FirebaseLegacyService } from '../../../core/services/firebase-legacy.service';
import {
  CustomField,
  CustomFieldType,
  FirebaseCollection,
  StripeProductId,
} from '../../../shared/models/models';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { map, take } from 'rxjs';
import {
  MatButtonToggle,
  MatButtonToggleGroup,
} from '@angular/material/button-toggle';
import { SfaEvent, SfaEventType } from '../events.models';
import { GrantingService } from '../../../core/services/granting.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DataPrepperService } from '../../../core/services/data-prepper.service';
import { Store } from '@ngxs/store';
import { OrganizationState } from '../../../core/store/organization/organization.state';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'sfa-new-event-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatHint,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    RouterLink,
    SvgIconComponent,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatSlideToggle,
  ],
  templateUrl: './new-event-dialog.component.html',
  styleUrl: './new-event-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewEventDialogComponent {
  protected readonly environment = environment;
  protected readonly CustomFieldType = CustomFieldType;
  protected readonly StripeProductId = StripeProductId;

  private toastr = inject(ToastrService);
  private grantingService = inject(GrantingService);
  private destroyRef = inject(DestroyRef);

  dialogRef = inject(DialogRef);
  data: SfaEvent = inject(DIALOG_DATA);
  formBuilder = inject(FormBuilder);
  firebaseService = inject(FirebaseLegacyService);
  dataPrepperService = inject(DataPrepperService);

  customFieldsAddedToForm = signal([] as CustomField[]); // !Important! Only custom fields which are already added to the form
  events = this.dataPrepperService.events$;
  currentOrganization = toSignal(
    inject(Store).select(OrganizationState.activeOrganization),
    { requireSync: true },
  );
  eventTypes: Signal<SfaEventType[] | undefined> = toSignal(
    this.firebaseService
      .getCollection(FirebaseCollection.EVENT_TYPES)
      .pipe(
        map((eventTypes: SfaEventType[]) => [
          <SfaEventType>{ id: '0', name: 'Standard' },
          ...eventTypes,
        ]),
      ),
  );

  editMode = !!this.data;

  form: FormGroup = this.formBuilder.group({
    title: [this.data?.title || '', Validators.required],
    date: [
      this.data?.date || new Date().toISOString().split('T')[0],
      Validators.required,
    ],
    typeId: [
      this.data?.typeId || this.eventTypes()?.[0].id,
      Validators.required,
    ],
    description: [this.data?.description || ''],
  });

  constructor() {
    this.firebaseService
      .getCollection(FirebaseCollection.EVENT_CUSTOM_FIELDS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((fields) => {
        fields.forEach((field: CustomField) => {
          const controlName = 'custom_' + field.id;
          if (!this.form.contains(controlName)) {
            this.form.addControl(
              controlName,
              this.formBuilder.control(
                (this.data as never)?.[controlName] || '',
              ),
            );
            this.customFieldsAddedToForm.update((prev) => [...prev, field]);
          }
        });
      });

    // Whenever the eventTypes are loaded, we patch the initial type to be "Standard" type
    effect(() => this.form.patchValue({ type: this.eventTypes()?.[0]?.id }));
  }

  /**
   * Save event, but only if eventCreation is allowed
   */
  onSubmit() {
    if (this.form.invalid) return;
    this.editMode ? this.editEvent() : this.createEvent();
  }

  createEvent() {
    // Check if the user is allowed to create an event for the given year
    const eventYear = new Date(this.form.value.date).getFullYear();
    if (!this.grantingService.isAllowedToCreateEventForYear(eventYear)) {
      this.grantingService.limitReached();
      return;
    }

    const event: SfaEvent = this.form.getRawValue();
    !event.memberCount && (event.memberCount = 0);

    this.firebaseService
      .addDoc(FirebaseCollection.EVENTS, event)
      .then(() => {
        this.toastr.success('Aktivität hinzugefügt');
        this.dialogRef.close();
      })
      .catch((error) => {
        this.toastr.error('Fehler! Bitte versuche es später erneut');
        console.error(error);
      });
  }

  editEvent() {
    // When - in edit mode - the year did not change, everything is fine, but when the year changed, we need to check if the user is allowed to create an event for the given year
    const eventYear = new Date(this.form.value.date).getFullYear();
    if (
      new Date(this.data.date).getFullYear() !== eventYear &&
      !this.grantingService.isAllowedToCreateEventForYear(eventYear)
    ) {
      this.grantingService.limitReached();
      return;
    }

    this.firebaseService
      .updateDoc(
        FirebaseCollection.EVENTS,
        this.data.id,
        this.form.getRawValue(),
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastr.success('Aktivität gespeichert');
          this.dialogRef.close();
        },
        error: (error) => {
          this.toastr.error('Fehler! Bitte versuche es später erneut');
          console.error(error);
        },
      });
  }
}
