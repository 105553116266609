<sfu-svg-icon
  class="close-icon"
  src="close"
  svgClass="icon-button"
  (click)="dialogRef.close()"
/>

<h3>
  @if (editMode) {
    {{ data.title }} bearbeiten
  } @else {
    Neue Aktivität
  }
</h3>
@if (
  currentOrganization().stripeReadableProductId === StripeProductId.FREE &&
  environment.maxEventsInFreePlan - events().length <= 5
) {
  <span class="font-color-primary"
    >Free-Plan: Du kannst noch
    {{ environment.maxEventsInFreePlan - events().length }} Aktivitäten anlegen
    bevor du auf einen Tarif upgraden musst. Unsere Tarife findest du
    <a
      class="link underline font-color-primary"
      routerLink="/account/products"
      (click)="dialogRef.close()"
      >hier.</a
    ></span
  ><br />
}

<form
  tabindex="0"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  (keydown.enter)="onSubmit()"
>
  <!-- static fields -->
  <div class="form-group">
    <label for="title">Titel*</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        required
        type="text"
        class="form-control"
        id="title"
        name="title"
        formControlName="title"
        maxlength="100"
      />
      <mat-hint
        *ngIf="form.controls['title'].invalid && form.controls['title'].touched"
      >
        Benötigt
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="date">Datum*</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        type="date"
        class="form-control"
        id="date"
        name="date"
        formControlName="date"
        maxlength="100"
      />
      <mat-hint
        *ngIf="form.controls['date'].invalid && form.controls['date'].touched"
      >
        Benötigt
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="typeId" class="label-with-link">
      Aktivitätsart

      @if (eventTypes()?.length === 1) {
        <a
          class="underline font-small"
          routerLink="events/settings"
          (click)="dialogRef.close()"
          >Aktivitätsart hinzufügen?</a
        >
      }
    </label>

    <mat-button-toggle-group
      name="typeId"
      aria-label="Aktivitätsart"
      formControlName="typeId"
      id="typeId"
    >
      @for (eventType of eventTypes(); track eventType.id) {
        <mat-button-toggle [value]="eventType.id"
          >{{ eventType.name }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>

  <div class="form-group">
    <label for="description">Protokoll</label>
    <mat-form-field appearance="outline">
      <textarea
        matInput
        formControlName="description"
        type="text"
        class="form-control"
        id="description"
        name="description"
        maxlength="500"
      ></textarea>
    </mat-form-field>
  </div>

  <!-- custom fields -->
  @for (customField of customFieldsAddedToForm(); track customField.id) {
    <div class="form-group">
      @switch (customField.type) {
        @case (CustomFieldType.BOOLEAN) {
          <label [for]="customField.id">{{ customField.name }}</label>
          <mat-slide-toggle [formControlName]="'custom_' + customField.id">
            {{
              form.controls["custom_" + customField.id].value ? "Ja" : "Nein"
            }}
          </mat-slide-toggle>
        }
        @default {
          <label [for]="customField.id">{{ customField.name }}</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              [type]="customField.type"
              class="form-control"
              [id]="customField.id"
              [name]="customField.id"
              [formControlName]="'custom_' + customField.id"
              maxlength="100"
            />
          </mat-form-field>
        }
      }
    </div>
  }

  @if (customFieldsAddedToForm().length === 0) {
    <a
      class="underline"
      routerLink="events/settings"
      (click)="dialogRef.close()"
      >Nicht alle gewünschten Felder vorhanden?</a
    >
    <br /><br />
  }

  @if (!editMode) {
    <span>Mitglieder können erst nach Erstellung hinzugefügt werden.</span>
  }

  <div class="actions">
    <button (click)="dialogRef.close()">Abbrechen</button>
    <button class="button-primary" type="submit">
      @if (editMode) {
        Speichern
      } @else {
        Hinzufügen
      }
    </button>
  </div>
</form>
